import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const DisbursementGoal = OurType.PrismaModel(
  "DisbursementGoal",
  {
    employeeId: Type.Number(),
    yearMonth: OurType.YearMonth(),
    amount: OurType.TypeMoney(),
  },
  {
    employee: ["", "Employee"],
  } as const
);
