import * as OurType from "../../typebox";

export const PersonalIdTypePh = OurType.OurEnum({
  umid: { title: "UMID" },
  sss: { title: "SSS" },
  passport: {},
  driversLicense: {},
  tin: { title: "TIN" },
  philhealth: { title: "PhilHealth" },
});
