import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const CreditHistoryPh = OurType.PrismaModel(
  "CreditHistoryPh",
  {
    id: Type.Number(),
    createdAt: OurType.DateType(),
    score: Type.Number(),
    reasons: Type.Array(Type.String()),
    rawResult: Type.Any(),
    underwritingId: Type.Number(),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
