import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const LoanDailyOverdue = OurType.PrismaModel(
  "LoanDailyOverdue",
  {
    loanId: Type.Number(),
    date: OurType.DateType(),
    dpd: Type.Number(),
  },
  {
    loan: ["", "Loan"],
  } as const
);
