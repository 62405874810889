import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { SaleChannel } from "./SaleChannel";

export const CustomerSaleChannelRevenue = OurType.PrismaModel(
  "CustomerSaleChannelRevenue",
  {
    id: Type.Number(),
    loanId: Type.Number(),
    date: OurType.LocalDate(),
    saleChannel: OurType.Nullable(SaleChannel),
    amount: OurType.TypeMoney(),
  },
  {
    invoice: ["", "Invoice"],
  } as const
);
