import { Nullable } from './ourType';
import { R } from '@getmo/common/vendor/remeda';
import {
  type Evaluate,
  type TArray,
  type TObject,
  type TOptional,
  type TProperties,
  type TSchema,
  Type,
} from '@sinclair/typebox';
import { assert } from '@sindresorhus/is';

export const PrismaModelKind = Symbol('PrismaModelKind');
export type PrismaModelKind = typeof PrismaModelKind;

export const RelationDescriptorsKind = Symbol('RelationDescriptors');
export type RelationDescriptorsKind = typeof RelationDescriptorsKind;

export const RelationModelsKind = Symbol('RelationModels');
export type RelationModelsKind = typeof RelationModelsKind;

type RelationModificator = 'Array' | 'Nullable' | '';
export type WrapByModificator<M extends RelationModificator, T extends TSchema> = M extends 'Array'
  ? TArray<T>
  : M extends 'Nullable'
    ? Nullable<T>
    : T;
const WrapByModificator = <M extends RelationModificator, T extends TSchema>(
  modificator: M,
  schema: T,
): WrapByModificator<M, T> =>
  (modificator === 'Array' ? Type.Array(schema) : modificator === 'Nullable' ? Nullable(schema) : schema) as never;

type RelationDescriptor = [RelationModificator, string];
type RelationDescriptors = Record<string, RelationDescriptor>;
export type PrismaModel<
  Name extends string = string,
  T extends TProperties = TProperties,
  RD extends RelationDescriptors = RelationDescriptors,
> = TObject<T> & { [PrismaModelKind]: Name; [RelationDescriptorsKind]: RD };

export const isPrismaModel = (value: unknown): value is PrismaModel =>
  !!value && typeof value === 'object' && PrismaModelKind in value;
export const PrismaModel = <Name extends string, T extends TProperties, RD extends RelationDescriptors>(
  name: Name,
  props: T,
  relationDescriptors: RD,
) =>
  Type.Object(props, { [PrismaModelKind]: name, [RelationDescriptorsKind]: relationDescriptors }) as PrismaModel<
    Name,
    T,
    RD
  >;

const modelOptions = (model: PrismaModel, relationModels?: RelationModels) => ({
  [PrismaModelKind]: model[PrismaModelKind],
  [RelationDescriptorsKind]: model[RelationDescriptorsKind],
  [RelationModelsKind]: relationModels,
});

export const ClientModel = <
  Name extends string,
  T extends TProperties,
  RD extends RelationDescriptors,
  K extends (keyof T)[],
>(
  model: PrismaModel<Name, T, RD>,
  keys: [...K],
): PrismaModel<Name, Pick<T, K[number]>, RD> => Type.Pick(model, keys, modelOptions(model)) as never;

type RelationModels = Record<string, PrismaModel>;
export const isModelWithRelationModels = (value: unknown): value is ModelWithRelationModels =>
  !!value && typeof value === 'object' && RelationModelsKind in value && !!value[RelationModelsKind];
export type ModelWithRelationModels<
  Name extends string = string,
  T extends TProperties = TProperties,
  RD extends RelationDescriptors = RelationDescriptors,
  RM extends RelationModels = RelationModels,
> = PrismaModel<Name, T, RD> & {
  [RelationModelsKind]: RM;
};
type MergeProps<T1 extends TProperties, T2 extends TProperties> = {
  [K in keyof T1 | keyof T2]: K extends keyof T2 ? T2[K] : K extends keyof T1 ? T1[K] : never;
};
type PropsWithOptRelations<
  T extends TProperties,
  RD extends RelationDescriptors,
  RM extends RelationModels,
> = MergeProps<
  Omit<T, keyof RD>,
  {
    [K in keyof RM & keyof RD]: TOptional<WrapByModificator<RD[K][0], RM[K]>>;
  }
>;
type PropsWithReqRelations<
  T extends TProperties,
  RD extends RelationDescriptors,
  RM extends RelationModels,
> = MergeProps<
  Omit<T, keyof RD>,
  {
    [K in keyof RM & keyof RD]: WrapByModificator<RD[K][0], RM[K]>;
  }
>;

export type ModelWithOptRelations<
  Name extends string = string,
  T extends TProperties = TProperties,
  RD extends RelationDescriptors = RelationDescriptors,
  RM extends RelationModels = RelationModels,
> = ModelWithRelationModels<Name, PropsWithOptRelations<T, RD, RM>, RD, RM>;

export type ModelWithReqRelations<
  Name extends string = string,
  T extends TProperties = TProperties,
  RD extends RelationDescriptors = RelationDescriptors,
  RM extends RelationModels = RelationModels,
> = ModelWithRelationModels<Name, PropsWithReqRelations<T, RD, RM>, RD, RM>;

export const ModelWithOptRelations = <
  Name extends string,
  T extends TProperties,
  RD extends RelationDescriptors,
  RM extends RelationModels,
>(
  model: PrismaModel<Name, T, RD>,
  relationModels: RM,
): ModelWithOptRelations<Name, T, RD, RM> =>
  Type.Object(
    {
      ...R.pipe(
        model.properties as TProperties,
        R.entries(),
        R.filter((e) => !(e[0] in model[RelationDescriptorsKind])),
        R.fromEntries(),
      ),
      ...R.mapValues(relationModels as RelationModels, (m, k) => {
        const d: RelationDescriptor | undefined = model[RelationDescriptorsKind][k];
        assert.truthy(d);
        return Type.Optional(WrapByModificator(d[0], m));
      }),
    },
    modelOptions(model, relationModels),
  ) as never;

export const ModelWithReqRelations = <
  Name extends string,
  T extends TProperties,
  RD extends RelationDescriptors,
  RM extends RelationModels,
>(
  model: PrismaModel<Name, T, RD>,
  relationModels: RM,
): ModelWithReqRelations<Name, T, RD, RM> =>
  Type.Object(
    {
      ...R.pipe(
        model.properties as TProperties,
        R.entries(),
        R.filter((e) => !(e[0] in model[RelationDescriptorsKind])),
        R.fromEntries(),
      ),
      ...R.mapValues(relationModels as RelationModels, (m, k) => {
        const d: RelationDescriptor | undefined = model[RelationDescriptorsKind][k];
        assert.truthy(d);
        return WrapByModificator(d[0], m);
      }),
    },

    modelOptions(model, relationModels),
  ) as never;

export type RelationModelsPickSelection<RM extends RelationModels> = keyof RM extends never
  ? Record<string, never>
  : Evaluate<{
      [K in keyof RM]?: RM[K] extends ModelWithRelationModels ? RelationPickSelection<RM[K]> : object;
    }>;
export type RelationPickSelection<M extends ModelWithRelationModels> = RelationModelsPickSelection<
  M[RelationModelsKind]
>;

export type PickRelationModels<RM extends RelationModels, S extends Record<string, unknown>> = Evaluate<{
  [K in keyof S & keyof RM]: S[K] extends Record<string, unknown>
    ? RM[K] extends ModelWithRelationModels
      ? PickRelations<RM[K], S[K]>
      : RM[K]
    : never;
}>;
export type PickRelations<M extends ModelWithRelationModels, S extends Record<string, unknown>> = ModelWithReqRelations<
  M[PrismaModelKind],
  M['properties'],
  M[RelationDescriptorsKind],
  PickRelationModels<M[RelationModelsKind], S>
>;

export const PickRelations = <M extends ModelWithRelationModels, S extends RelationPickSelection<M>>(
  model: M,
  select: S,
): PickRelations<M, S> =>
  ModelWithReqRelations(model, {
    ...R.mapValues(select as Record<string, unknown>, (s, k) => {
      const m = model[RelationModelsKind][k];
      assert.truthy(m);
      return PickRelations(m as ModelWithRelationModels, s as Record<string, object>);
    }),
  }) as never;
