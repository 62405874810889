import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const LoanRestructure = OurType.PrismaModel(
  "LoanRestructure",
  {
    loanId: Type.Number(),
    date: OurType.LocalDate(),
    loanAgreementNumber: OurType.Nullable(Type.String()),
    termsId: Type.Number(),
  },
  {
    loan: ["", "Loan"],
    terms: ["", "TermsSuggestion"],
  } as const
);
