import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const KtpOcrData = OurType.PrismaModel(
  "KtpOcrData",
  {
    documentId: Type.Number(),
    province: Type.String(),
    cityDistrict: Type.String(),
    bloodGroup: Type.String(),
    religion: Type.String(),
    address: Type.String(),
    validUntil: Type.String(),
    citizenship: Type.String(),
    nik: Type.String(),
    fullName: Type.String(),
    work: Type.String(),
    placeOfBirth: Type.String(),
    subdistrict: Type.String(),
    gender: Type.String(),
    rt: Type.String(),
    rw: Type.String(),
    dob: OurType.LocalDate(),
    maritalStatus: Type.String(),
    villageSubdistrict: Type.String(),
  },
  {
    document: ["", "Document"],
  } as const
);
