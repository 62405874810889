import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { FieldVisitReputation } from "./FieldVisitReputation";
import { FieldVisitTenure } from "./FieldVisitTenure";
import { FieldVisitHouseOwnership } from "./FieldVisitHouseOwnership";
import { FieldVisitBusinessPermit } from "./FieldVisitBusinessPermit";
import { FieldVisitEyeTest } from "./FieldVisitEyeTest";
import { FieldVisitGross } from "./FieldVisitGross";

export const UnderwritingPhFieldVisitData = OurType.PrismaModel(
  "UnderwritingPhFieldVisitData",
  {
    underwritingId: Type.Number(),
    geoPos: Type.Array(Type.Number()),
    isLivingInAddress: OurType.Nullable(
      Type.Boolean({ title: "Living in the given address" })
    ),
    communityTenure: OurType.Nullable(FieldVisitTenure),
    barangayReputation: OurType.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByBarangay: OurType.Nullable(
      Type.Boolean({ title: "Relative in office or the force" })
    ),
    hasCollectorVisits: OurType.Nullable(
      Type.Boolean({ title: "Do other collectors visit the borrower" })
    ),
    isAlwaysOpen: OurType.Nullable(
      Type.Boolean({ title: "Is the business always open" })
    ),
    communityReputation: OurType.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByNeighbor: OurType.Nullable(
      Type.Boolean({ title: "Relative in office or the force" })
    ),
    houseOwnership: OurType.Nullable(FieldVisitHouseOwnership),
    businessPermit: OurType.Nullable(FieldVisitBusinessPermit),
    businessTenure: OurType.Nullable(FieldVisitTenure),
    eyeTest: OurType.Nullable(FieldVisitEyeTest),
    dailyGross: OurType.Nullable(FieldVisitGross),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
