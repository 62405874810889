import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPhBankAccountMonthData = OurType.PrismaModel(
  "UnderwritingPhBankAccountMonthData",
  {
    bankAccountId: Type.Number(),
    month: OurType.YearMonth(),
    totalCredits: OurType.TypeMoney(),
    totalDebits: OurType.TypeMoney(),
    endingBalance: OurType.TypeMoney(),
    dailyAverageBalance: OurType.TypeMoney(),
  },
  {
    bankAccount: ["", "UnderwritingPhBankAccount"],
  } as const
);
