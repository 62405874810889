import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Region } from "./Region";
import { CityPh } from "./CityPh";
import { UserRole } from "./UserRole";
import { EmployeeTag } from "./EmployeeTag";
import { UnderwritingPipeline } from "./UnderwritingPipeline";

export const Employee = OurType.PrismaModel(
  "Employee",
  {
    id: Type.Number(),
    isActive: Type.Boolean({ default: true }),
    tags: Type.Array(EmployeeTag),
    email: OurType.Nullable(Type.String()),
    phone: OurType.Nullable(Type.String()),
    name: OurType.Nullable(Type.String()),
    role: UserRole,
    region: OurType.Nullable(Region),
    pipelines: Type.Array(UnderwritingPipeline),
    phCities: Type.Array(CityPh),
    hubspotId: OurType.Nullable(
      Type.String({
        description:
          "HubSpot has 2 entities for each employee: Contact and Owner. I'm not sure what's the difference, but Contacts are connected to Owners.\nAlso Owner contains more information. For now we store only Owner ids on our side.\nhttps://developers.hubspot.com/docs/api/crm/owners",
      })
    ),
  },
  {
    underwritingNotes: ["Array", "UnderwritingNote"],
    createdLoans: ["Array", "Loan"],
    termsSuggestions: ["Array", "TermsSuggestion"],
    underwritingActions: ["Array", "UnderwritingAction"],
    ownedLeadOnboardings: ["Array", "Onboarding"],
    creditHistories: ["Array", "CreditHistory"],
    fieldVisits: ["Array", "Underwriting"],
    underwritings: ["Array", "Underwriting"],
    DisbursementGoal: ["Array", "DisbursementGoal"],
    loanActionReporters: ["Array", "LoanAction"],
    loanActionAssignees: ["Array", "LoanAction"],
    inChargeLoan: ["Array", "Loan"],
  } as const
);
