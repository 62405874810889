import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { PersonalIdTypePh } from "./PersonalIdTypePh";

export const Person = OurType.PrismaModel(
  "Person",
  {
    id: Type.Number(),
    name: OurType.Nullable(Type.String()),
    phone: OurType.FullPhone(),
    email: OurType.Nullable(OurType.Email()),
    kyc: OurType.Nullable(Type.Boolean()),
    personalIdNumber: OurType.Nullable(Type.String({ title: "Personal ID" })),
    personalIdTypePh: OurType.Nullable(PersonalIdTypePh),
    dob: OurType.Nullable(OurType.LocalDate({ title: "Date of birth" })),
    photo: OurType.Nullable(Type.String()),
    photoId: OurType.Nullable(Type.String()),
  },
  {
    onboardings: ["Array", "Onboarding"],
  } as const
);
