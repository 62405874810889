import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const CreditHistoryLoanHistoricalData = OurType.PrismaModel(
  "CreditHistoryLoanHistoricalData",
  {
    id: Type.Number(),
    referenceDate: Type.String(),
    daysPastDue: OurType.Nullable(Type.Number()),
    overdue: OurType.Nullable(OurType.TypeMoney()),
    debitBalance: OurType.Nullable(OurType.TypeMoney()),
    currentMonthRealization: OurType.Nullable(OurType.TypeMoney()),
    contractStatus: OurType.Nullable(Type.String()),
    creditHistoryLoanContractCode: Type.String(),
    creditHistoryId: Type.Number(),
  },
  {
    creditHistoryLoan: ["", "CreditHistoryLoan"],
  } as const
);
