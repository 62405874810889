import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const LoanHistoryIntervalValues = OurType.PrismaModel(
  "LoanHistoryIntervalValues",
  {
    loanId: Type.Number(),
    daysOnBooks: Type.Number(),
    cumulativeRepaidAmount: OurType.TypeMoney(),
  },
  {
    loan: ["", "Loan"],
  } as const
);
