import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingFinancialData = OurType.PrismaModel(
  "UnderwritingFinancialData",
  {
    underwritingId: Type.Number(),
    previousYearRevenue: OurType.Nullable(
      OurType.TypeMoney({ title: "Revenue for previous year" })
    ),
    currentYearRevenue: OurType.Nullable(
      OurType.TypeMoney({ title: "Revenue for current year" })
    ),
    grossMargin: OurType.Nullable(
      OurType.TypePercent({ title: "Gross margin" })
    ),
    operatingMargin: OurType.Nullable(
      OurType.TypePercent({ title: "Operating margin" })
    ),
    avgMonthlyRevenue6Months: OurType.Nullable(
      OurType.TypeMoney({ title: "Average monthly revenue for 6 months" })
    ),
    avgMonthlyRevenue12Months: OurType.Nullable(
      OurType.TypeMoney({ title: "Average monthly revenue for 12 months" })
    ),
    avgTransactions6Months: OurType.Nullable(
      Type.Number({ title: "Average transactions for 6 months" })
    ),
    monthsWithZeroRevenue12Months: OurType.Nullable(
      Type.Number({ title: "Number of months with zero Revenue (12 months)" })
    ),
  },
  {
    underwriting: ["", "Underwriting"],
    prevYearMonthlyRevenue: ["Nullable", "UnderwritingPrevYearMonthlyRevenue"],
  } as const
);
