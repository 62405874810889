import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const BankTransactionSummary = OurType.PrismaModel(
  "BankTransactionSummary",
  {
    onboardingId: Type.Number(),
    avgMonthlyCreditAmount: OurType.TypeMoney(),
  },
  {
    onboarding: ["", "Onboarding"],
  } as const
);
