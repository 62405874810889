import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingInterviewData = OurType.PrismaModel(
  "UnderwritingInterviewData",
  {
    underwritingId: Type.Number(),
    actualBusinessAddress: OurType.Nullable(Type.String()),
    website: OurType.Nullable(OurType.Uri()),
    activitiesDetail: OurType.Nullable(
      Type.String({ title: "Activities in detail" })
    ),
    buyers: OurType.Nullable(Type.String({ title: "Who are the buyers" })),
    loanPurposeDescription: OurType.Nullable(
      Type.String({ title: "Detailed description of the purpose of the loan" })
    ),
    customerRequestAmount: OurType.Nullable(
      OurType.TypeMoney({
        title: "Customer's request in the application form (amount)",
      })
    ),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
