import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingCreditHistoryData = OurType.PrismaModel(
  "UnderwritingCreditHistoryData",
  {
    underwritingId: Type.Number(),
    totalDebtLegalEntitiesFounders: OurType.Nullable(
      OurType.TypeMoney({
        title:
          "Total debt on all loans and credits of legal entities and founders",
      })
    ),
    maxLoanServicing12Months: OurType.Nullable(
      OurType.TypeMoney({
        title:
          "Maximum amount of single-entity servicing of loans and borrowings for 12 months",
      })
    ),
    monthlyPaymentLegalEntitiesFounders: OurType.Nullable(
      OurType.TypeMoney({
        title:
          "Monthly payment on all loans and credits of legal entities and founders",
      })
    ),
    overduePaymentsLoans12MonthsUpTo30Days: OurType.Nullable(
      Type.Number({
        title: "Overdue payments and loans for 12 months up to 30 days",
        unit: "pcs",
      })
    ),
    overduePaymentsLoans24MonthsMoreThan60Days: OurType.Nullable(
      Type.Number({
        title: "Overdue payments and loans for 24 months more than 60 days",
        unit: "pcs",
      })
    ),
    loansCreditsReceivedLastMonth: OurType.Nullable(
      Type.Number({
        title:
          "Number of loans and credits received during the month prior to application",
        unit: "pcs",
      })
    ),
    amountLoansCreditsReceivedLastMonth: OurType.Nullable(
      OurType.TypeMoney({
        title:
          "Amount of loans and credits received for the month prior to application",
      })
    ),
    borrowerCreditBurdenRelativeToAvgMonthlyRevenue: OurType.Nullable(
      OurType.TypePercent({
        title:
          "Borrower's credit burden in relation to average monthly revenue",
      })
    ),
    monthlyPaymentsLoansCreditsRelativeToAvgMonthlyRevenue: OurType.Nullable(
      OurType.TypePercent({
        title:
          "Monthly payments on loans and credits relative to average monthly revenue",
      })
    ),
    pdlLoans12Months: OurType.Nullable(
      Type.Number({ title: "Number of PDL for 12 months", unit: "pcs" })
    ),
    dscr: OurType.Nullable(
      Type.Number({ title: "DSCR, xN of monthly revenue" })
    ),
    isShopeeDebitor: Type.Boolean({ default: false }),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
