import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { DocumentType } from "./DocumentType";
import { Bank } from "./Bank";

export const Document = OurType.PrismaModel(
  "Document",
  {
    id: Type.Number(),
    createdAt: OurType.DateType(),
    fileName: Type.String(),
    filePath: Type.String(),
    type: DocumentType,
    onboardingId: Type.Number(),
    bank: OurType.Nullable(Bank),
    coownerId: OurType.Nullable(Type.Number()),
    isDeleted: Type.Boolean({ default: false }),
    isUploadedByClient: Type.Boolean({ default: false }),
    originalId: OurType.Nullable(Type.Number()),
    md5Checksum: Type.String(),
    ocrStarted: Type.Boolean({ default: false }),
    ocrDocId: OurType.Nullable(Type.Number()),
    pdfCreator: OurType.Nullable(Type.String()),
    pdfProducer: OurType.Nullable(Type.String()),
    validationScore: OurType.Nullable(OurType.TypePercent()),
  },
  {
    original: ["Nullable", "Document"],
    ocrDoc: ["Nullable", "Document"],
    ocrSourceDocs: ["Array", "Document"],
    ktpOcrData: ["Nullable", "KtpOcrData"],
    npwpOcrData: ["Nullable", "NpwpOcrData"],
    onboarding: ["", "Onboarding"],
    coowner: ["Nullable", "OnboardingCoowner"],
    bankStatements: ["Array", "BankStatement"],
    document: ["Array", "Document"],
  } as const
);
