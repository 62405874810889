import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const CreditHistoryLoan = OurType.PrismaModel(
  "CreditHistoryLoan",
  {
    id: Type.String(),
    createdAt: OurType.DateType(),
    customerId: Type.String(),
    contractCode: Type.String(),
    namaLender: Type.String(),
    role: Type.String(),
    startDate: OurType.LocalDate(),
    dueDate: OurType.Nullable(OurType.LocalDate()),
    contractStatus: Type.String(),
    economicSector: OurType.Nullable(Type.String()),
    interestRateAnnual: Type.String(),
    initialCreditLimit: OurType.TypeMoney(),
    creditLimit: OurType.TypeMoney(),
    debitBalance: OurType.TypeMoney(),
    interest: Type.Number(),
    tenure: OurType.Nullable(Type.Number()),
    closed: Type.Boolean(),
    creditHistoryId: Type.Number(),
  },
  {
    historicalData: ["Array", "CreditHistoryLoanHistoricalData"],
    creditHistory: ["", "CreditHistory"],
  } as const
);
