import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Region } from "./Region";
import { LegalType } from "./LegalType";
import { SaleChannelType } from "./SaleChannelType";
import { KycStatus } from "./KycStatus";
import { OnboardingStatus } from "./OnboardingStatus";
import { BusinessCategoryPh } from "./BusinessCategoryPh";
import { CityPh } from "./CityPh";
import { OnboardingQualification } from "./OnboardingQualification";
import { CityId } from "./CityId";
import { BusinessCategoryId } from "./BusinessCategoryId";

export const Onboarding = OurType.PrismaModel(
  "Onboarding",
  {
    id: Type.Number(),
    name: OurType.Nullable(Type.String()),
    phone: OurType.Nullable(OurType.FullPhone()),
    email: OurType.Nullable(OurType.Email()),
    createdAt: OurType.DateType(),
    updatedAt: OurType.DateType(),
    submittedAt: OurType.DateType(),
    status: OnboardingStatus,
    region: Region,
    utm: OurType.Nullable(Type.Any()),
    loanAmount: OurType.Nullable(OurType.TypeMoney()),
    revenue: OurType.Nullable(OurType.TypeMoney()),
    loanPurpose: OurType.Nullable(Type.String()),
    loanDuration: OurType.Nullable(Type.Number()),
    originalId: OurType.Nullable(Type.Number()),
    shopName: OurType.Nullable(Type.String()),
    legalType: OurType.Nullable(LegalType),
    legalCategory: Type.Array(Type.String()),
    cityId: OurType.Nullable(CityId),
    businessCategoryId: OurType.Nullable(BusinessCategoryId),
    typeOfSalesChannel: OurType.Nullable(SaleChannelType),
    businessCategoryPh: OurType.Nullable(BusinessCategoryPh),
    yearsBusinessOperation: OurType.Nullable(
      Type.Number({ title: "How many years your business operates" })
    ),
    cityPh: OurType.Nullable(CityPh),
    location: OurType.Nullable(Type.String()),
    landmark: OurType.Nullable(Type.String()),
    geoPos: Type.Array(Type.Number()),
    checkingAccountPh: OurType.Nullable(
      Type.Boolean({
        title: "Does the client have checking account under his name ",
      })
    ),
    phHasAfs: OurType.Nullable(
      Type.Boolean({ title: "Does the client have ITR/AFS for 2023?" })
    ),
    isBusinessOwnerPh: OurType.Nullable(
      Type.Boolean({ title: "Does client own the business" })
    ),
    isReadyForVisitPh: OurType.Nullable(
      Type.Boolean({ title: "Is client ready for field visit" })
    ),
    qualification: OurType.Nullable(OnboardingQualification),
    vidaKycStatus: OurType.Nullable(KycStatus),
    step: OurType.Nullable(Type.String()),
    top5Suppliers: OurType.Nullable(
      OurType.Multiline({
        title: "List of Top 5 Suppliers and Top 5 Customers",
      })
    ),
    outstandingDebt: OurType.Nullable(Type.String()),
    hubspotContactId: OurType.Nullable(Type.String()),
    applicantId: OurType.Nullable(Type.Number()),
    leadSource: OurType.Nullable(Type.String()),
    hubspotLeadSource: Type.String({ title: "Lead Source", default: "" }),
    hubspotTrafficSource: Type.String({ title: "Traffic Source", default: "" }),
    leadOwnerId: OurType.Nullable(Type.Number()),
    slackReportThreadId: OurType.Nullable(Type.String()),
    finfraUserId: OurType.Nullable(Type.String()),
    shownName: OurType.Nullable(Type.String()),
  },
  {
    original: ["Nullable", "Onboarding"],
    copies: ["Array", "Onboarding"],
    underwriting: ["Nullable", "Underwriting"],
    applicant: ["Nullable", "Person"],
    saleChannels: ["Array", "OnboardingSaleChannel"],
    documents: ["Array", "Document"],
    companyContact: ["Nullable", "OnboardingCompanyContact"],
    emergencyContact: ["Nullable", "OnboardingEmergencyContact"],
    coowners: ["Array", "OnboardingCoowner"],
    leadOwner: ["Nullable", "Employee"],
    personUnverifiedData: ["Nullable", "PersonUnverifiedData"],
    ayoconnectCards: ["Array", "AyoconnectCard"],
    xenditCustomer: ["Nullable", "XenditCustomer"],
    bankTransactionSummary: ["Nullable", "BankTransactionSummary"],
  } as const
);
