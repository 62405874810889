import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const Kbli = OurType.PrismaModel(
  "Kbli",
  {
    code: Type.String(),
    parentCode: OurType.Nullable(Type.String()),
    kbliId: Type.String(),
    title: Type.String(),
    description: Type.String(),
    originalTitle: Type.String(),
    originalDesc: Type.String(),
  },
  {
    parent: ["Nullable", "Kbli"],
    children: ["Array", "Kbli"],
    underwritingRequestDescriptionData: [
      "Array",
      "UnderwritingRequestDescriptionData",
    ],
  } as const
);
