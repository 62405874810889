import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { LoanActionType } from "./LoanActionType";

export const LoanAction = OurType.PrismaModel(
  "LoanAction",
  {
    id: Type.Number(),
    type: LoanActionType,
    loanId: Type.Number(),
    date: OurType.DateType(),
    comment: OurType.Multiline(),
    handler: OurType.Nullable(Type.String({ title: "3rd Party Handling" })),
    promiseToPayDate: OurType.Nullable(OurType.LocalDate()),
    assigneeId: OurType.Nullable(Type.Number({ title: "Assignee" })),
    reporterId: Type.Number(),
  },
  {
    loan: ["", "Loan"],
    assignee: ["Nullable", "Employee"],
    reporter: ["", "Employee"],
    currentlyAssignedTo: ["Array", "Loan"],
    collectionVisit: ["Nullable", "CollectionVisit"],
  } as const
);
