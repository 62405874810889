import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { FieldVisitEyeTest } from "./FieldVisitEyeTest";

export const CollectionVisit = OurType.PrismaModel(
  "CollectionVisit",
  {
    id: Type.Number(),
    isBusinessOperational: Type.Boolean({
      title: "Is business still operational",
    }),
    isClientInTheStore: Type.Boolean({ title: "Is client in the store" }),
    inventoryCondition: FieldVisitEyeTest,
    promiseToPayAmount: OurType.Nullable(OurType.TypeMoney()),
  },
  {
    assignment: ["", "LoanAction"],
  } as const
);
