import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPrevYearMonthlyRevenue = OurType.PrismaModel(
  "UnderwritingPrevYearMonthlyRevenue",
  {
    financialId: Type.Number(),
    september: OurType.Nullable(OurType.TypeMoney({ title: "September" })),
    august: OurType.Nullable(OurType.TypeMoney({ title: "August" })),
    july: OurType.Nullable(OurType.TypeMoney({ title: "July" })),
    june: OurType.Nullable(OurType.TypeMoney({ title: "June" })),
    may: OurType.Nullable(OurType.TypeMoney({ title: "May" })),
    april: OurType.Nullable(OurType.TypeMoney({ title: "April" })),
    march: OurType.Nullable(OurType.TypeMoney({ title: "March" })),
    february: OurType.Nullable(OurType.TypeMoney({ title: "February" })),
    january: OurType.Nullable(OurType.TypeMoney({ title: "January" })),
    december: OurType.Nullable(OurType.TypeMoney({ title: "December" })),
    november: OurType.Nullable(OurType.TypeMoney({ title: "November" })),
    october: OurType.Nullable(OurType.TypeMoney({ title: "October" })),
  },
  {
    underwritingFinancialData: ["", "UnderwritingFinancialData"],
  } as const
);
