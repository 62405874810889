import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const CreditHistory = OurType.PrismaModel(
  "CreditHistory",
  {
    id: Type.Number(),
    status: Type.Number(),
    statusText: Type.String(),
    createdAt: OurType.DateType(),
    pdfRequestId: Type.String(),
    coownerId: OurType.Nullable(Type.Number()),
    corporate: Type.Boolean({ default: false }),
    employeeId: OurType.Nullable(Type.Number()),
    underwritingId: Type.Number(),
  },
  {
    loans: ["Array", "CreditHistoryLoan"],
    employee: ["Nullable", "Employee"],
    underwriting: ["", "Underwriting"],
    coowner: ["Nullable", "OnboardingCoowner"],
  } as const
);
