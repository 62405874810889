import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPhCashCycle = OurType.PrismaModel(
  "UnderwritingPhCashCycle",
  {
    underwritingId: Type.Number(),
    paymentTermsSuppliers: OurType.Nullable(
      Type.Number({ title: "Payment Terms with Suppliers (days)" })
    ),
    paymentTermsCustomers: OurType.Nullable(
      Type.Number({ title: "Payment Terms with Customers (days)" })
    ),
    goodsSellTime: OurType.Nullable(
      Type.Number({ title: "Time to sell goods / deliver service (days)" })
    ),
    receivablesTotal: OurType.Nullable(
      Type.Number({ title: "Total Receivables from Aging List" })
    ),
    receivablesOnTime: OurType.Nullable(
      Type.Number({ title: "On Time Receivables from Aging List" })
    ),
    receivablesLate0to30Days: OurType.Nullable(
      Type.Number({ title: "Receivables late 0-30 days from Aging List" })
    ),
    receivablesLate30to60Days: OurType.Nullable(
      Type.Number({ title: "Receivables late 30-60 days from Aging List" })
    ),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
