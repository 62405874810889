import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const OnboardingEmergencyContact = OurType.PrismaModel(
  "OnboardingEmergencyContact",
  {
    onboardingId: Type.Number(),
    name: OurType.Nullable(Type.String()),
    phone: OurType.Nullable(OurType.FullPhone()),
    email: OurType.Nullable(OurType.Email()),
    relation: OurType.Nullable(Type.String()),
  },
  {
    onboarding: ["", "Onboarding"],
  } as const
);
