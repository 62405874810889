import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const ShopProduct = OurType.PrismaModel(
  "ShopProduct",
  {
    id: Type.Number(),
    category: OurType.Nullable(Type.String()),
    name: Type.String(),
    photo: OurType.Nullable(Type.String()),
    article: Type.String(),
    stock: Type.Number(),
    price: OurType.TypeDecimal(),
    oldPrice: OurType.Nullable(OurType.TypeDecimal()),
    externalId: Type.String(),
    connectionId: Type.Number(),
  },
  {
    connection: ["", "ShopConnection"],
    orderItems: ["Array", "ShopOrderItem"],
  } as const
);
