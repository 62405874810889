import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { InvoiceAdditionType } from "./InvoiceAdditionType";

export const InvoiceAddition = OurType.PrismaModel(
  "InvoiceAddition",
  {
    id: Type.Number(),
    loanId: Type.Number(),
    date: OurType.LocalDate(),
    type: InvoiceAdditionType,
    title: Type.String(),
    amount: OurType.TypeMoney(),
  },
  {
    invoice: ["", "Invoice"],
  } as const
);
