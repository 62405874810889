import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { PaymentStatus } from "./PaymentStatus";

export const AyoconnectPayment = OurType.PrismaModel(
  "AyoconnectPayment",
  {
    id: Type.Number(),
    createdAt: OurType.DateType(),
    updatedAt: OurType.DateType(),
    amount: OurType.TypeMoney(),
    status: PaymentStatus,
    invoiceDate: OurType.LocalDate(),
    flowId: OurType.Nullable(Type.String()),
    failureReason: OurType.Nullable(Type.String()),
    maskedCard: OurType.Nullable(Type.String()),
    loanId: Type.Number(),
    cardReferenceNumber: OurType.Nullable(Type.String()),
  },
  {
    loan: ["", "Loan"],
    card: ["Nullable", "AyoconnectCard"],
    invoice: ["", "Invoice"],
  } as const
);
