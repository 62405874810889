import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const OnboardingCoowner = OurType.PrismaModel(
  "OnboardingCoowner",
  {
    id: Type.Number(),
    onboardingId: Type.Number(),
    name: OurType.Nullable(Type.String()),
    role: OurType.Nullable(Type.String()),
    phone: OurType.Nullable(OurType.FullPhone()),
    email: OurType.Nullable(OurType.Email()),
    ktp: OurType.Nullable(Type.String({ title: "NIK" })),
    shareholding: OurType.Nullable(OurType.TypePercent()),
    finfraUserId: OurType.Nullable(Type.String()),
  },
  {
    onboarding: ["", "Onboarding"],
    documents: ["Array", "Document"],
    creditHistory: ["Array", "CreditHistory"],
    creditHistorySlackRequests: ["Array", "CreditHistorySlackRequest"],
  } as const
);
