import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const Invoice = OurType.PrismaModel(
  "Invoice",
  {
    loanId: Type.Number(),
    date: OurType.LocalDate(),
    baseAmount: OurType.TypeMoney(),
  },
  {
    loan: ["", "Loan"],
    schedule: ["", "RepaymentSchedule"],
    saleChannelRevenues: ["Array", "CustomerSaleChannelRevenue"],
    additions: ["Array", "InvoiceAddition"],
    ayoconnectPayments: ["Array", "AyoconnectPayment"],
    xenditPayments: ["Array", "XenditPayment"],
  } as const
);
