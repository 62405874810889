import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Bank } from "./Bank";
import { Currency } from "./Currency";
import { BankStatementParser } from "./BankStatementParser";

export const BankStatement = OurType.PrismaModel(
  "BankStatement",
  {
    id: Type.Number(),
    documentId: Type.Number(),
    bank: Bank,
    startDate: OurType.LocalDate(),
    endDate: OurType.LocalDate(),
    startBalance: OurType.Nullable(OurType.TypeMoney()),
    endBalance: OurType.Nullable(OurType.TypeMoney()),
    accountNumber: Type.String(),
    currency: Currency,
    clientName: Type.String(),
    address: Type.String(),
    isFullyParsed: Type.Boolean(),
    parser: BankStatementParser,
    parsingErrorPageNumber: OurType.Nullable(Type.Number()),
    parsingErrorRowNumber: OurType.Nullable(Type.Number()),
  },
  {
    document: ["", "Document"],
  } as const
);
