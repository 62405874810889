import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingPipeline } from "./UnderwritingPipeline";
import { UnderwritingStatus } from "./UnderwritingStatus";

export const Underwriting = OurType.PrismaModel(
  "Underwriting",
  {
    id: Type.Number(),
    createdAt: OurType.DateType(),
    updatedAt: OurType.DateType(),
    visitedAt: OurType.Nullable(OurType.DateType()),
    order: Type.Number(),
    pipeline: UnderwritingPipeline,
    status: UnderwritingStatus,
    fieldAgentId: OurType.Nullable(Type.Number()),
    underwriterId: OurType.Nullable(Type.Number()),
    selectedTermsId: OurType.Nullable(Type.Number()),
  },
  {
    onboarding: ["", "Onboarding"],
    fieldAgent: ["Nullable", "Employee"],
    underwriter: ["Nullable", "Employee"],
    selectedTerms: ["Nullable", "TermsSuggestion"],
    creditHistory: ["Array", "CreditHistory"],
    creditHistorySlackRequests: ["Array", "CreditHistorySlackRequest"],
    creditHistoryPh: ["Array", "CreditHistoryPh"],
    notes: ["Array", "UnderwritingNote"],
    phFieldVisitData: ["Nullable", "UnderwritingPhFieldVisitData"],
    phOverall: ["Nullable", "UnderwritingPhOverall"],
    phCashCycle: ["Nullable", "UnderwritingPhCashCycle"],
    phObligations: ["Array", "UnderwritingPhObligation"],
    phReferenceChecks: ["Array", "UnderwritingPhReferenceCheck"],
    phAfs: ["Array", "UnderwritingPhAfs"],
    phBankAccounts: ["Array", "UnderwritingPhBankAccount"],
    phSalesReports: ["Array", "UnderwritingPhSalesReport"],
    requestDescriptionData: ["Nullable", "UnderwritingRequestDescriptionData"],
    interviewData: ["Nullable", "UnderwritingInterviewData"],
    mainMarketplaceData: ["Nullable", "UnderwritingMainMarketplaceData"],
    contractorsData: ["Nullable", "UnderwritingContractorsData"],
    financialData: ["Nullable", "UnderwritingFinancialData"],
    creditHistoryData: ["Nullable", "UnderwritingCreditHistoryData"],
    actions: ["Array", "UnderwritingAction"],
    termsSuggestions: ["Array", "TermsSuggestion"],
    disbursementState: ["Nullable", "DisbursementState"],
    loan: ["Nullable", "Loan"],
    jointeffPaymentHistory: ["Nullable", "JointeffPaymentHistory"],
    stageTimings: ["Array", "UnderwritingStageTimings"],
  } as const
);
