import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { NotificationType } from "./NotificationType";

export const NotificationUnsubscription = OurType.PrismaModel(
  "NotificationUnsubscription",
  {
    email: Type.String(),
    type: NotificationType,
    createdAt: OurType.DateType(),
  },
  {} as const
);
