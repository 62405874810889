import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const CreditHistorySlackRequest = OurType.PrismaModel(
  "CreditHistorySlackRequest",
  {
    id: Type.Number(),
    threadTs: Type.String(),
    isCorporate: Type.Boolean(),
    underwritingId: Type.Number(),
    coownerId: OurType.Nullable(Type.Number()),
  },
  {
    underwriting: ["", "Underwriting"],
    coowner: ["Nullable", "OnboardingCoowner"],
  } as const
);
