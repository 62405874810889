import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const PhBank = OurType.PrismaModel(
  "PhBank",
  {
    id: Type.Number(),
    title: Type.String(),
    createdAt: OurType.DateType(),
    updatedAt: OurType.DateType(),
  },
  {
    underwritingBankAccount: ["Array", "UnderwritingPhBankAccount"],
  } as const
);
