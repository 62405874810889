import * as OurType from "../../typebox";

export const UnderwritingNegative = OurType.OurEnum({
  amount: { title: "Amount reasons (not enough) " },
  loanTerm: { title: "Loan term reasons" },
  notRelevant: { title: "Not relevant" },
  noCreditHistory: { title: "Client have no credit history" },
  creditHistory: { title: "Credit history is negative" },
  weakFinancialPosition: { title: "Weak financial position" },
  legal: { title: "Legal reasons" },
  setOfFactors: { title: "Set of factors (see comment)" },
  productCriteria: { title: "Does not meet the product criteria" },
  misleadLender: { title: "Tried to mislead the lender" },
  guaranteeRejection: { title: "Personal Guarantee Request Rejection" },
  noSignificantNegatives: { title: "No significant negative was found" },
});
