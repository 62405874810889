import * as OurType from "../../typebox";

export const DocumentType = OurType.OurEnum({
  ktp: { title: "KTP" },
  npwp: { title: "NPWP" },
  npwp_personal: { title: "Personal NPWP" },
  npwp_corporate: { title: "Corporate NPWP" },
  bank_statement_personal: { title: "Personal bank statement" },
  bank_statement_corporate: { title: "Corporate bank statement" },
  sk: { title: "SK" },
  trademark_certificate: { title: "Trademark certificate" },
  business_license: { title: "Business license" },
  company_profile: { title: "Company profile" },
  customer_invoice_3m: { title: "Customer invoice (3 months)" },
  balance: { title: "Balance" },
  nib: { title: "NIB" },
  sale_channel_own_proof: { title: "Sales channel ownership proof" },
  sales_data: { title: "Sales data" },
  sales_screen: { title: "Sales screenshot" },
  ocr: { title: "OCR" },
  creditHistory: {},
  accountsReceivableAgingReport: {},
  other: {},
  personalId: {},
  proofOfBilling: {},
  selfie: {},
  storePhoto: {},
  loanAgreement: {},
  loanPersonalGuaranteeMainApplicant: {
    title: "Personal guarantee of main applicant",
  },
  loanSecondPersonalGuarantee: { title: "Second personal guarantee" },
  loanBoardOfCommissionersApproval: { title: "BOC approval" },
  loanCircularResolutionOfShareholders: {
    title: "Circular resolution of shareholders",
  },
  loanPostDatedCheques: { title: "Post dated cheques" },
  loanSiteVisitReport: { title: "Site visit report" },
});
