import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const AyoconnectCard = OurType.PrismaModel(
  "AyoconnectCard",
  {
    referenceNumber: Type.String(),
    authCode: Type.String(),
    publicUserId: OurType.Nullable(Type.String()),
    maskedCard: OurType.Nullable(Type.String()),
    accountToken: OurType.Nullable(Type.String()),
    bankCode: OurType.Nullable(Type.String()),
    onboardingId: Type.Number(),
  },
  {
    onboarding: ["", "Onboarding"],
    ayoconnectPayments: ["Array", "AyoconnectPayment"],
  } as const
);
