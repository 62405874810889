import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const PostalCode = OurType.PrismaModel(
  "PostalCode",
  {
    id: Type.Number(),
    province: Type.String(),
    cityDistrict: Type.String(),
    subdistrict: Type.String(),
    villageSubdistrict: Type.String(),
    code: Type.Number(),
  },
  {} as const
);
