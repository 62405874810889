import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { PersonalIdTypePh } from "./PersonalIdTypePh";

export const PersonUnverifiedData = OurType.PrismaModel(
  "PersonUnverifiedData",
  {
    onboardingId: Type.Number(),
    fullName: Type.String({ title: "Full name" }),
    personalIdNumber: Type.String({ title: "Personal ID" }),
    personalIdTypePh: OurType.Nullable(PersonalIdTypePh),
    dob: OurType.LocalDate({ title: "Birth Date" }),
    selfiePhotoIds: Type.Array(Type.String()),
    selfieFaceScores: Type.Array(OurType.TypePercent()),
    selfieBusinessScores: Type.Array(OurType.TypePercent()),
  },
  {
    onboarding: ["", "Onboarding"],
  } as const
);
