import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingStatus } from "./UnderwritingStatus";

export const UnderwritingStageTimings = OurType.PrismaModel(
  "UnderwritingStageTimings",
  {
    underwritingId: Type.Number(),
    stage: UnderwritingStatus,
    hoursInStatus: Type.Number(),
    hoursInRevision: Type.Number(),
    workingHoursInStatus: Type.Number(),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
