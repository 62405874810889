import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const VirtualAccount = OurType.PrismaModel(
  "VirtualAccount",
  {
    number: Type.String(),
    name: Type.String(),
  },
  {
    loans: ["Array", "Loan"],
    repayments: ["Array", "Repayment"],
  } as const
);
