import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingMainMarketplaceData = OurType.PrismaModel(
  "UnderwritingMainMarketplaceData",
  {
    underwritingId: Type.Number(),
    followersCount: OurType.Nullable(
      Type.Number({ title: "Number of followers" })
    ),
    reviewsCount: OurType.Nullable(Type.Number({ title: "Number of reviews" })),
    reputationLevel: OurType.Nullable(Type.String()),
    chatResponseRate: OurType.Nullable(
      OurType.TypePercent({ title: "Chat response rate" })
    ),
    avarageRating: OurType.Nullable(Type.Number({ title: "Average rating" })),
  },
  {
    underwriting: ["", "Underwriting"],
  } as const
);
