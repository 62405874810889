import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Region } from "./Region";
import { RepaymentSource } from "./RepaymentSource";

export const Repayment = OurType.PrismaModel(
  "Repayment",
  {
    id: Type.Number(),
    createdAt: OurType.DateType(),
    phone: OurType.Nullable(OurType.FullPhone()),
    date: OurType.DateType(),
    amount: OurType.TypeMoney(),
    email: OurType.Nullable(Type.String()),
    region: Region,
    source: RepaymentSource,
    externalId: OurType.Nullable(Type.String()),
    description: Type.String({ default: "" }),
    isBeforeInstamoney: Type.Boolean({
      description:
        "Repayment loaded from Payment Data spreadsheet but missing in Instamoney",
      default: false,
    }),
    vaNumber: Type.String(),
  },
  {
    virtualAccount: ["", "VirtualAccount"],
    assignments: ["Array", "RepaymentAssignment"],
  } as const
);
