import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const ShopOrderItem = OurType.PrismaModel(
  "ShopOrderItem",
  {
    id: Type.Number(),
    quantity: Type.Number(),
    price: OurType.TypeDecimal(),
    oldPrice: OurType.Nullable(OurType.TypeDecimal()),
    modelId: OurType.Nullable(Type.String()),
    itemId: Type.String(),
    orderId: Type.Number(),
    productId: OurType.Nullable(Type.Number()),
  },
  {
    order: ["", "ShopOrder"],
    product: ["Nullable", "ShopProduct"],
  } as const
);
